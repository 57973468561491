<template>
   <div class="view">
       <el-row :gutter="12"
               v-loading="isLoading"
       >
           <el-col :span="8">
               <el-card shadow="always"> {{lang['view.user']}} : {{info.user}} </el-card>
           </el-col>
           <el-col :span="8">
               <el-card shadow="hover"> {{lang['view.agent']}} : {{info.agent}} </el-card>
           </el-col>
           <el-col :span="8">
               <el-card class="box-card">{{lang['view.key']}} : {{info.key}}</el-card>
           </el-col>
       </el-row>

       <el-row :gutter="12" style="margin-top: 10px"
               v-loading="isLoading"
       >
           <el-col :span="8">
               <el-card shadow="always"> {{ lang['view.money.reg'] }} : {{info.money.reg}} </el-card>
           </el-col>
           <el-col :span="8">
               <el-card shadow="hover"> {{ lang['view.money.rec'] }} : {{info.money.rec}} </el-card>
           </el-col>
           <el-col :span="8">
               <el-card class="box-card"> {{ lang['view.online'] }} : {{info.online}}</el-card>
           </el-col>
       </el-row>
   </div>

</template>

<script>
import { ElNotification } from 'element-plus'
export default {
    name: "indexData",
    data(){
        return {
            info:{
                key:'...',//拥有多少卡密[注册码+充值码]
                user:'...',//有多少用户
                agent:'...',//有多少下级代理商
                money:{
                    rec:'...',//[余额]充值
                    reg:'...',//[余额]注册
                },
                online:'...',//当前在线人数
            },
            lang:this.$lang.getLang('layout','indexData'),
            isLoading:true,
        }
    },
    methods:{
        getData(){
            this.isLoading = true;
            ElNotification({
                message: 'Loading..',
                type: 'info',
            });
            this.$api.post('Api.index/getIndexData',{}).then((res)=>{
                this.isLoading = false;
                let data = res.data;
                let code = data.code != null ? res.data.code : null;
                if (code == 100){
                    this.info = {
                        key: data.key == null ? -1 : data.key,
                        user:data.user ?? -2,
                        agent:data.agent ?? -3,
                        money:{
                            rec: data.money_rec ?? -4,
                            reg: data.money_reg ?? -5
                        },
                        online: data.online ?? -6,
                    };
                }
                ElNotification({
                    message: this.lang['msg.loadSuccess'],
                    type: 'success',
                });
            }).catch(error=>{
                this.isLoading = false;
                this.$helper.axiosCatch(error);
            });
        },
    },
    mounted() {
        this.getData();
    }
}
</script>

<style scoped>
.view{
    height: 200px;
    line-height: 80px;
}

.box-card {
    width: 480px;
}

</style>